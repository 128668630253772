import React from "react"
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CookieConsent from "react-cookie-consent";
import "src/templates/organisms/Popup/styles.css";

const Popup = () => {
 const theme = useTheme();
 return (
  <Box>
   <CookieConsent
    disableStyles
    location="none"
    overlay
    overlayClasses="overlayclass"
    cookieName={"cumplo-cookies"}
    style={{ padding: 16 }}
    buttonWrapperClasses="buttonWrapper"
    buttonText="Aceptar todas las cookies"
    enableDeclineButton
    declineButtonText="Rechazar las cookies opcionales"
   >
    <Box sx={{ padding: 2}}>
     <Typography variant="h3" align="center" color="primary.dark" sx={{pb: 5}}> Te damos la bienvenida a Cumplo Perú </Typography>
     <Typography variant="body2" color={theme.palette.text.primary} sx={{ fontSize: 14 }}>
     Además de las cookies estrictamente necesarias para el funcionamiento de este sitio web, utilizamos los siguientes tipos de cookies para mejorar tu experiencia y nuestros servicios: <b>Cookies funcionales</b> para mejorar tu experiencia (por ejemplo, recordar la configuración), <b>Cookies de rendimiento</b> para medir el rendimiento del sitio web y mejorar tu experiencia, <b>Cookies de publicidad/targeting</b>, que son establecidas por terceros con los que ejecutamos campañas publicitarias y nos permiten ofrecer anuncios relevantes. <br/><br/>
    Puedes retirar tu consentimiento a las cookies en cualquier momento una vez que haya entrado en el sitio web a través del enlace en la política de privacidad, el cual puedes encontrar en la parte inferior de cada página del sitio web. <br/><br/>
    Revisa nuestra <a href="/terminos-y-regulaciones/" className="link">política de cookies</a> para obtener más información.
     </Typography>
    </Box>
   </CookieConsent>
  </Box>
 )
}

export { Popup }